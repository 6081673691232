import React, { useEffect } from 'react';
import { navigate } from 'gatsby';
import { graphql } from 'gatsby';
/* --- import Components ----*/
import Layout from '../components/Layout';
import { Section } from 'src/components/Section';
import { TextContainer, SectionTitle, Sentence } from 'src/components/Typography';
import { TopbarStaticSpace } from 'src/components/Topbar';
import { FormHeaderMainTitle } from 'src/components/Form/elements';
import { Card } from 'src/components/Blog';
/* --- import Style ----*/
import colors from 'src/style/colors';
import sizes from 'src/style/sizes';
import { mediaQueries } from 'src/style/breakpoints';

const Validation = ({
  pageContext: { content },
  location,
  data: {
    allMarkdownRemark: { edges },
  },
}) => {
  const blogPostHasDate = edges.filter((edge) => !!edge.node.frontmatter.date);
  const blogPostIsNotCovered = edges
    .filter((edge) => blogPostHasDate && edge.node.frontmatter.published === true)
    .map((edge) => <Card key={edge.node.id} post={edge.node} />);

  const redirect = () => {
    navigate('/');
  };
  setTimeout(redirect, 30000);

  useEffect(() => {
    if (content?.id === 'autorisation-prelevement') {
      window.dataLayer.push({
        event: 'debitAuthorization',
        businessType: 'B2C',
        pageType: 'validation',
        pageName: 'Autorisation enregistrée',
      });
    }
  }, [content?.id]);

  return (
    <Layout
      pathname={location.pathname}
      businessType="B2C|B2B"
      pageName={content?.title}
      pageType="validation"
      metaTitle={content.metaTitle}
    >
      <div
        css={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          position: 'relative',
          [mediaQueries.phoneOnly]: {
            marginBottom: '0px',
          },
        }}
      >
        <Section
          withoutCenteredContent
          theme="primary"
          css={{
            [mediaQueries.desktopUp]: {
              height: sizes.purpleBgHeightDesktop,
            },
            // height: '60vh',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <TopbarStaticSpace />
          <FormHeaderMainTitle
            css={{
              textAlign: 'center',
              /* width: '70%', */
              [mediaQueries.phoneOnly]: {
                fontSize: 32,
              },
            }}
          >
            {content.title}
          </FormHeaderMainTitle>

          {content.id === 'inscription-reunion' && location.state && location.state.calendarURL ? (
            <Sentence
              css={{
                textAlign: 'center',
                width: '80%',
                margin: '24px auto',
                [mediaQueries.phoneOnly]: {
                  width: '70%',
                },
              }}
            >
              Cliquez{' '}
              <a
                href={location.state.calendarURL}
                target="_blank"
                rel="noopener noreferrer"
                css={{ textDecoration: 'none', color: colors.yellow, fontWeight: 'bold' }}
              >
                ici
              </a>{' '}
              pour ajouter la réunion à votre calendrier.
            </Sentence>
          ) : (
            <TextContainer
              css={{
                textAlign: 'center',
                width: '60%',
                margin: '24px auto',
                [mediaQueries.phoneOnly]: {
                  width: '70%',
                },
              }}
              dangerouslySetInnerHTML={{ __html: content.subtitle }}
            />
          )}
        </Section>
      </div>
      <Section
        theme="secondary"
        css={{
          height: '100%',
          [mediaQueries.tabletPortraitUp]: {
            padding: '64px 0px',
          },
        }}
      >
        <SectionTitle
          isCenter
          css={{
            marginBottom: 50,
          }}
        >
          Ces articles pourraient vous intéresser
        </SectionTitle>
        <div
          css={{
            display: 'flex',
            flexDirection: 'row',
            flex: 1,
            flexWrap: 'wrap',
            height: '100%',
            [mediaQueries.phoneOnly]: {
              marginTop: 30,
            },
          }}
        >
          {blogPostIsNotCovered.slice(0, 3).map((edge) => (
            <Card key={edge.props.post.id} post={edge.props.post} />
          ))}
        </div>
      </Section>
    </Layout>
  );
};

export default Validation;

export const pageQuery = graphql`
  query {
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          id
          excerpt(pruneLength: 250)
          frontmatter {
            date(formatString: "DD MMMM YYYY", locale: "fr")
            title
            author
            featuredImage {
              childImageSharp {
                gatsbyImageData(width: 970)
              }
            }
            tags
            preview
            published
          }
          fields {
            slug
          }
        }
      }
    }
  }
`;
